import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import H2 from "../components/typography/h2"

export default function Template({ data }) {
  const {
    md: { html, frontmatter },
    poster: {
      childImageSharp: { fluid: posterProps },
    },
  } = data

  console.log(posterProps)

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="relative mx-auto">
        <div className="absolute bottom-0 left-0 right-0 z-50 px-4 mx-auto text-white bg-transparent sm:bg-hollow">
          <H2 className="w-full max-w-4xl mx-auto my-4 sm:pt-0">
            {frontmatter.title}
          </H2>
        </div>
        <Img
          className="absolute top-0 left-0 object-cover max-h-screen"
          fluid={posterProps}
        />
      </div>
      <div style={{ background: "#EBEDEB" }} className="px-4 pb-8">
        <div className="max-w-4xl mx-auto">
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!, $poster: String!) {
    md: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        title
      }
    }
    poster: file(relativePath: { eq: $poster }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
